/* NAVIGATION */
nav {
    box-shadow: 0px 5px 0px #dedede;
    ul {
        list-style: none;
        text-align: center;
        li {
            display: inline-block;
            div.icon-with-text {
                display: flex;
                align-items: center;
                padding: 7px 10px;
                margin: 0 10px;
                &::after{ transition: all .5s; }
                &::before{ transition: all .5s; }
                &:hover{ color: #216796; }
                a {
                    padding-left: 10px;
                    text-decoration: none;
                    color: $primary-color;
                    font-weight: 800;
                    text-transform: uppercase;
                }
                .icon {
                    margin: 0px;
                }
            }
        }
    }
}

.fa-solid {
    color: $primary-color;
}

/* stroke */
nav.stroke ul li div{
    position: relative;
}

nav.stroke ul li div:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: '.';
    color: transparent;
    background: #aaa;
    height: 1px;
}

nav.stroke ul li div:hover:after {
    width: 100%;
}
