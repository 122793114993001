// Career
#career {
  background-color: $primary-color;
  background-image: linear-gradient(
    135deg,
    $primary-color 0%,
    $secondary-color 100%
  );
  color: $white-color;
  height: 100%;
  border-top: 0px;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
  padding-bottom: 5%;

  @include respond(tab-land) {
    height: 100%;
    -webkit-clip-path: none;
    clip-path: none;
  }

  .career-wrapper{
    @include respond(phone) {
      padding-bottom: 5rem;
    }
        
    // Timeline
    .timeline {
      width: 100%;
      position: relative;
      margin: 30px auto;
      // padding: 0 10px;
      @include prefix(transition, all .4s ease);

      // Timeline vertical line
      &:before {
        content:"";
        width: 4px;
        height: 90%;
        background: $white-color;
        left: 50%;
        top: 0;
        position: absolute;
        @include respond(phone) { 
          left: 5%;
        }
      }

      &:after {
        content: "";
        clear: both;
        display: table;
        width: 100%;
      }
      
      .timeline-item {
        margin-bottom: 35px;
        position: relative;
        @extend %clearfix;

        .timeline-icon {
          background: $white-color;
          width: 50px;
          height: 50px;
          position: absolute;
          top: 0;
          left: 50%;
          overflow: hidden;
          margin-left: -23px;
          @include prefix(border-radius, 50%);

          @include respond(phone) { 
            left: 5%;
          }

          img, svg {
            position: relative;
            top: 14px;
          }
        }

        .timeline-content {
          width: 42%;
          background: $white-color;
          padding: 20px 20px 10px 20px;

          @include prefix(box-shadow, 0 3px 0 rgba(0,0,0,0.1));
          @include prefix(border-radius, 5px);
          @include prefix(transition, all .3s ease);
          @include respond(phone) { width: 85%; }

          h2 {
            padding: 10px;
            background: $primary-color;
            color: $white;
            margin: -20px -20px 0 -20px;
            font-size: $mid-font-size;
            @include prefix(border-radius, 3px 3px 0 0);
          }
          h4 {
            padding-top: 10px;
            color: $secondary-color;
            font-size: 2rem;
            @include respond(phone) {
              font-size: 2rem;
            }
          }
          p, a {
            color: $dark-blue-text;
            @include respond(phone) {
              font-size: 1.5rem;
            }
          }
          
          &.left {
            float: left;
            text-align: right;
            @include respond(phone) { 
              float: right;
              text-align: left;
            }
          }

          &.right {
            float: right;
            text-align: left;
          }
        }

        .timeline-date {
          padding: 10px;
          color: $light-grey;
          position: absolute; 

          @include respond(phone) { 
            top: -10%;
            width: 100%;
            h4 {
              display: inline;
            }
          }

          &.left {
            right: 55%;
            float: left;
            text-align: right;

            @include respond(phone) { 
              right: 0;
              left: 15%;
              float: right;
              text-align: left;
            }  
          }

          &.right {
            left: 55%;
            float: right;
            text-align: left;

            @include respond(phone) { 
              right: 0;
              left: 15%;
              float: right;
              text-align: left;
            }
          }
        }

        & .thumbnail {
          border: none;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
          transition: all 0.2s ease-out;
          box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
          transition: 0.5s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
            0.5s box-shadow,
            0.5s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  
          @include respond(phone) {
            border: 1px solid $light-grey;
            box-shadow: none;
            margin-bottom: 3.2rem;
          }
        }
      }
    }
  }
}
